import { createSlice } from "@reduxjs/toolkit"

export const clientFormSlice = createSlice({
    name: "formArray",
    initialState: {
    FormArr:[],
    eletype:""
    },
    reducers: {
        FormArrState: (state, action) => {
            state.FormArr=action.payload
        },
        Eletype:(state, action)=>{
           state.eletype=action.payload
        }
    }
})
export const {
    FormArrState,
    Eletype
} = clientFormSlice.actions
export default clientFormSlice.reducer