// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import formArray from './formArray'

const rootReducer = {
  navbar,
  layout,
  formArray
}

export default rootReducer
