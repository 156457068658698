import { Ability } from '@casl/ability'
import { getUserData } from '@utils'
import { initialAbility } from './initialAbility'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
//window.localStorage.clear()
const user = getUserData()
let existingAbility = null
if(user){    
    existingAbility = user ? user.ability : null
}else{
    existingAbility  = null
}




export default new Ability(existingAbility || initialAbility)
